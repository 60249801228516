import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input"];

    unlock() {
        // Toggles the disabled attribute of the text field
        this.inputTarget.disabled = !this.inputTarget.disabled;
        // Optionally change the background color for a visual cue
        if (this.inputTarget.disabled) {
            this.inputTarget.classList.add("bg-gray-100", "cursor-not-allowed");
        } else {
            this.inputTarget.classList.remove("bg-gray-100", "cursor-not-allowed");
        }
    }
}