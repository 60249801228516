import {Controller} from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify";

export default class extends Controller {
    connect() {
        if (this.element.tagify) {
            return;
        }
        this.initializeTagify();
    }

    initializeTagify() {
        this.tagify = new Tagify(this.element, {
            delimiters: ",",
            maxTags: 10,
            dropdown: {
                enabled: 0,
                closeOnSelect: false
            }
        });

        // Pre-populate Tagify with existing tags as individual tags
        this.tagify.addTags(this.element.value);

        // Ensure the form data is correctly formatted
        this.element.form.addEventListener('submit', this.handleFormSubmit.bind(this));
    }

    handleFormSubmit(event) {
        const tagifyValues = this.tagify.value.map(tag => tag.value).join(', ');
        this.element.value = tagifyValues;
    }
}