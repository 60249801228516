import tippy from "tippy.js"

const format_selection_bold = `
<a class="text-white" data-action="mousedown->format#bold">
  Bold
</a>
`

const format_selection_italic = `
<a class="text-white" data-action="mousedown->format#italic">
  Italic
</a>
`
const format_selection_strikethrough = `
<a class="text-white" data-action="mousedown->format#strikethrough">
  Strike
</a>
`

const format_selection_link = `
<a class="text-white" data-action="mousedown->format#link">
  Link
</a>
`

const format_selection_code = `
<a class="text-white" data-action="mousedown->format#code">
  Code
</a>
`

function format_selection_menu() {
    return (`
  <div class="p-1" data-controller="format">
    ${format_selection_bold}
    <span class="mx-1 text-gray-500">|</span>
    ${format_selection_italic}
    <span class="mx-1 text-gray-500">|</span>
    ${format_selection_strikethrough}
    <span class="mx-1 text-gray-500">|</span>
    ${format_selection_code}
    <span class="mx-1 text-gray-500">|</span>
    ${format_selection_link}
  </div>
  `)
}

export function show_format_selection_menu(element) {
    let box = window.getSelection().getRangeAt(0).getBoundingClientRect()
    return tippy(element, {
        allowHTML: true,
        appendTo: document.body,
        content: format_selection_menu(),
        interactive: true,
        interactiveBorder: 100,
        maxWidth: 500,
        getReferenceClientRect: () => box,
        onHidden: (instance) => {
            instance.destroy()
        }
    }).show()
}
