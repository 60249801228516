// import {Controller} from "@hotwired/stimulus";
// import EditorJS from "@editorjs/editorjs";
//
// // These are the plugins
// import CodeTool from "@editorjs/code";
// import Header from "@editorjs/header";
// import ImageTool from "@editorjs/image";
// // import List from "@editorjs/list";
// import Paragraph from "@editorjs/paragraph";
// import Checklist from '@editorjs/checklist';
// import NestedList from '@editorjs/nested-list'
// import Table from '@editorjs/table';
// import LinkTool from '@editorjs/link';
// import Alert from 'editorjs-alert';
// import DragDrop from 'editorjs-drag-drop';
//
// // Connects to data-controller="editor"
// export default class extends Controller {
//     static targets = ["article_content"];
//
//     csrfToken() {
//         const metaTag = document.querySelector("meta[name='csrf-token']");
//         return metaTag ? metaTag.content : "";
//     }
//
//     connect() {
//         // console.log("Hello, Stimulus!", this.element);
//
//         const initialContent = this.getInitialContent();
//
//         this.contentEditor = new EditorJS({
//             holder: this.article_contentTarget,
//             data: initialContent,
//             onReady: () => {
//                 new DragDrop(this.contentEditor);
//             },
//             tools: {
//                 header: {
//                     class: Header,
//                 },
//                 paragraph: {
//                     class: Paragraph,
//                     config: {
//                         inlineToolbar: true,
//                     },
//                 },
//                 linkTool: {
//                     class: LinkTool,
//                     config: {
//                         endpoint: 'https://app.foundersboxx.com/fetch_url', // endpoint: 'https://yourdomain.com/fetch_url'
//                     }
//                 },
//                 alert: Alert,
//                 code: CodeTool,
//                 table: Table,
//                 checklist: {
//                     class: Checklist,
//                     inlineToolbar: true,
//                 },
//                 list: {
//                     class: NestedList,
//                     inlineToolbar: true,
//                     config: {
//                         defaultStyle: 'ordered'
//                     },
//                 },
//                 image: {
//                     class: ImageTool,
//                     config: {
//                         endpoints: {
//                             byFile: `/articles/upload_image`,
//                         },
//                         additionalRequestHeaders: {
//                             "X-CSRF-Token": this.csrfToken(),
//                         },
//                     },
//                 },
//             },
//         });
//
//         this.element.addEventListener("submit", this.saveEditorData.bind(this));
//     }
//
//     getInitialContent() {
//         const hiddenContentField = document.getElementById(
//             "article_content_hidden"
//         );
//         if (hiddenContentField && hiddenContentField.value) {
//             return JSON.parse(hiddenContentField.value);
//         }
//         return {};
//     }
//
//     async saveEditorData(event) {
//         event.preventDefault();
//
//         const outputData = await this.contentEditor.save();
//         const articleForm = this.element;
//
//         const hiddenInput = document.getElementById("article_content_hidden");
//
//         hiddenInput.value = JSON.stringify(outputData);
//         articleForm.submit();
//     }
// }


import {Controller} from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";
import debounce from 'lodash.debounce';

// Plugins
import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import Paragraph from "@editorjs/paragraph";
import Checklist from "@editorjs/checklist";
import NestedList from "@editorjs/nested-list";
import Table from "@editorjs/table";
import LinkTool from "@editorjs/link";
import Alert from "editorjs-alert";
import DragDrop from "editorjs-drag-drop";

// Connects to data-controller="editor"
export default class extends Controller {
    static targets = ["article_content"];

    csrfToken() {
        const metaTag = document.querySelector("meta[name='csrf-token']");
        return metaTag ? metaTag.content : "";
    }

    connect() {
        const initialContent = this.getInitialContent();

        this.contentEditor = new EditorJS({
            holder: this.article_contentTarget,
            data: initialContent,
            onReady: () => {
                new DragDrop(this.contentEditor);
            },
            tools: {
                header: Header,
                paragraph: {
                    class: Paragraph,
                    config: {
                        inlineToolbar: true,
                    },
                },
                linkTool: {
                    class: LinkTool,
                    config: {
                        endpoint: 'http://localhost:3000/fetch_url',
                    },
                },
                alert: Alert,
                code: CodeTool,
                table: Table,
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'ordered',
                    },
                },
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: `/articles/upload_image`,
                        },
                        additionalRequestHeaders: {
                            "X-CSRF-Token": this.csrfToken(),
                        },
                    },
                },
            },
        });

        this.element.addEventListener("submit", this.saveEditorData.bind(this));

        // Set up autosave
        this.autosave = debounce(this.autoSave.bind(this), 5000);
        this.article_contentTarget.addEventListener("input", this.autosave);
    }

    disconnect() {
        this.article_contentTarget.removeEventListener("input", this.autosave);
    }

    getInitialContent() {
        const hiddenContentField = document.getElementById("article_content_hidden");
        if (hiddenContentField && hiddenContentField.value) {
            return JSON.parse(hiddenContentField.value);
        }
        return {};
    }

    async saveEditorData(event) {
        event.preventDefault();

        const outputData = await this.contentEditor.save();
        const articleForm = this.element;

        const hiddenInput = document.getElementById("article_content_hidden");

        hiddenInput.value = JSON.stringify(outputData);
        articleForm.submit();
    }

    async autoSave() {
        const outputData = await this.contentEditor.save();

        const articleId = this.data.get("articleIdValue");

        fetch(`/articles/${articleId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.csrfToken(),
            },
            body: JSON.stringify({
                article: {
                    content: JSON.stringify(outputData)
                }
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    console.log("Article saved automatically");
                } else {
                    console.error("Error autosaving article", data.errors);
                }
            });
    }
}