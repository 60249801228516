import tippy from "tippy.js"

const change_fragment_h1 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->change-fragment#h1">
  <span class="font-bold">Heading 1</span>
</a>
`

const change_fragment_h2 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->change-fragment#h2">
  <span class="font-semibold">Heading 2</span>
</a>
`

const change_fragment_h3 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->change-fragment#h3">
  Heading 3
</a>
`

const change_fragment_p = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->change-fragment#paragraph">
  Paragraph
</a>
`

const change_fragment_pre = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->change-fragment#pre">
  Code Block
</a>
`

const change_fragment_delete = `
<a class="cursor-pointer px-1" data-action="mousedown->change-fragment#delete">
  Delete
</a>
`

function change_fragment_menu() {
    return (`
  <div class="change-fragment-menu">
    <div class="absolute z-10 w-32 theme-background border border-gray-200 rounded-md shadow-lg flex flex-col space-y-1 p-2">
      ${change_fragment_h1}
      ${change_fragment_h2}
      ${change_fragment_h3}
      ${change_fragment_p}
      ${change_fragment_pre}
      <hr class="my-2 border-gray-300">
      ${change_fragment_delete}
    </div>
  </div>
  `)
}

export function show_change_fragment_menu(element) {
    return tippy(element, {
        allowHTML: true,
        content: change_fragment_menu(),
        interactive: true,
        interactiveBorder: 200,
        // inlinePositioning: true,
        hideOnClick: true,
        // placement: "bottom",
        offset: [0, 0],
        arrow: false,
        onHidden: (instance) => {
            instance.destroy()
        }
    }).show()
}
