import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
    static targets = ["section", "entry"];
    static values = {updateUrl: String};

    // State to prevent concurrent updates
    updating = false;

    connect() {
        this.sectionTargets.forEach(section => {
            new Sortable(section, {
                group: 'canvas-sections', // Allow drag-and-drop between sections
                animation: 150,
                handle: '.drag-handle',
                onEnd: this.handleSortEnd.bind(this)
            });
        });
    }

    addEntry(event) {
        const sectionName = event.currentTarget.dataset.sectionName;
        const section = this.sectionTargets.find(s => s.dataset.sectionName === sectionName);
        const entryIndex = section.querySelectorAll("input").length;

        const entryHtml = `
            <div class="entry flex items-center mb-2">
                <div class="drag-handle cursor-move pr-2">☰</div>
                <input type="text" 
                       class="px-2 py-1 rounded mr-2 w-full form-control border-0" 
                       placeholder="Add details for ${sectionName.replace(/_/g, ' ')}"
                       data-canvas-target="entry" 
                       data-entry-index="${entryIndex}"
                       data-action="keydown.enter->canvas#updateEntry blur->canvas#updateEntry">
                <button class="text-red-500 ml-2" data-action="click->canvas#removeEntry">✕</button>
            </div>`;

        const addButton = section.querySelector("[data-action='click->canvas#addEntry']");
        addButton.insertAdjacentHTML("beforebegin", entryHtml);

        this.updateSectionEntries(sectionName);  // Ensure function is accessible here
    }

    removeEntry(event) {
        const entry = event.currentTarget.closest(".entry");
        const section = entry.closest("[data-canvas-target='section']");
        entry.remove();
        this.updateSectionEntries(section.dataset.sectionName);
    }

    updateEntry(event) {
        if (event.type === "keydown" && event.key !== "Enter") {
            return;
        }

        const sectionName = event.currentTarget.closest("[data-canvas-target='section']").dataset.sectionName;
        this.updateSectionEntries(sectionName);
    }

    updateSectionEntries(sectionName) {
        const section = this.sectionTargets.find(s => s.dataset.sectionName === sectionName);
        const entries = Array.from(section.querySelectorAll("input[data-canvas-target='entry']")).map(entry => entry.value);

        this.sendUpdateRequest({[sectionName]: entries});  // Ensure compatibility with sendUpdateRequest
    }

    handleSortEnd(event) {
        // Avoid concurrent updates
        if (this.updating) return;

        const fromSection = event.from.dataset.sectionName;
        const toSection = event.to.dataset.sectionName;

        // Update entries for both the source and target sections
        const fromEntries = this.getSectionEntries(fromSection);
        const toEntries = this.getSectionEntries(toSection);

        // Send updates for both sections in a single request
        this.sendUpdateRequest({[fromSection]: fromEntries, [toSection]: toEntries});
    }

    getSectionEntries(sectionName) {
        const section = this.sectionTargets.find(s => s.dataset.sectionName === sectionName);
        return Array.from(section.querySelectorAll("input[data-canvas-target='entry']")).map(entry => entry.value);
    }


    sendUpdateRequest(sectionsData) {
        if (this.updating) return; // Prevent concurrent updates
        this.updating = true;

        fetch(this.updateUrlValue, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            },
            body: JSON.stringify({sections: sectionsData}),
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok');
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    alert("Error updating the canvas: " + data.message);
                } else {
                    console.log("Update successful for sections:", sectionsData);
                }
            })
            .catch(error => {
                console.error("Error:", error);
            })
            .finally(() => {
                this.updating = false;
            });
    }

    reloadSectionEntries(sectionName) {
        // Function to re-fetch section entries if needed. This could be an AJAX request to fetch the latest state.
        // This is a placeholder to be implemented if necessary.
    }
}