import tippy from "tippy.js"

const add_fragment_h1 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#h1">
  <span class="font-bold">Heading 1</span>
</a>
`

const add_fragment_h2 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#h2">
  <span class="font-semibold">Heading 2</span>
</a>
`

const add_fragment_h3 = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#h3">
  <span class="font-semibold">Heading 3</span>
</a>
`

const add_fragment_p = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#paragraph">
  Paragraph
</a>
`

const add_fragment_pre = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#pre">
  Code Block
</a>
`
const add_fragment_image = `
<a class="cursor-pointer px-1 text-gray-600 dark:text-white hover:text-primary-300 hover:dark:text-primary-300" data-action="mousedown->add-fragment#image">
  Image
</a>
`

function add_fragment_menu() {
    return (`
  <div class="add-fragment-menu">
    <div class="absolute z-10 w-32 theme-background border-gray-200 rounded-md shadow-lg flex flex-col space-y-1 p-2">
      ${add_fragment_h1}
      ${add_fragment_h2}
      ${add_fragment_h3}
      ${add_fragment_p}
      ${add_fragment_pre}
      ${add_fragment_image}
    </div>
  </div>
  `)
}

export function show_add_fragment_menu(element) {
    return tippy(element, {
        allowHTML: true,
        content: add_fragment_menu(),
        interactive: true,
        interactiveBorder: 200,
        // inlinePositioning: true,
        hideOnClick: true,
        // placement: "bottom",
        offset: [0, 0],
        arrow: false,
        onHidden: (instance) => {
            instance.destroy()
        }
    }).show()
}
