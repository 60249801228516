import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["fields", "template"];

    initialize() {
        this.usedPlatforms = [];
    }

    connect() {
        this.updatePlatforms();
    }

    add(event) {
        event.preventDefault();
        event.stopPropagation();

        let time = new Date().getTime();
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, time);

        this.fieldsTarget.insertAdjacentHTML("beforeend", content);

        this.updatePlatforms();
    }

    remove(event) {
        event.preventDefault();
        event.stopPropagation();

        let wrapper = event.target.closest(".social-media-fields");
        if (wrapper) {
            wrapper.querySelector("input[name*='_destroy']").value = true;
            wrapper.style.display = "none";

            let selectedPlatform = wrapper.querySelector("select").value;
            if (selectedPlatform) {
                this.usedPlatforms = this.usedPlatforms.filter(
                    (platform) => platform !== selectedPlatform
                );
            }

            this.updatePlatforms();
        }
    }

    updatePlatforms() {
        this.usedPlatforms = [];

        let selects = this.element.querySelectorAll(
            ".social-media-fields:not([style*='display: none']) select.platform-select"
        );
        selects.forEach((select) => {
            let selected = select.value;
            if (selected) {
                this.usedPlatforms.push(selected);
            }

            select.querySelectorAll("option").forEach((option) => {
                if (this.usedPlatforms.includes(option.value)) {
                    option.disabled = selected !== option.value;
                }
            });
        });
    }
}