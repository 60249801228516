import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["latitude", "longitude", "location", "address"];

    connect() {
    }

    handleRetrieve(event) {

        const feature = event.detail.features[0];

        if (feature && feature.geometry && feature.geometry.coordinates) {
            const coordinates = feature.geometry.coordinates;
            const longitude = coordinates[0];
            const latitude = coordinates[1];
            const formattedLocation = feature.properties.place_formatted;

            this.latitudeTarget.value = latitude;
            this.longitudeTarget.value = longitude;
            this.locationTarget.value = formattedLocation;
        } else {
        }
    }

    handleAddressRetrieve(event) {
        const feature = event.detail.features[0];

        if (feature && feature.properties) {
            const fullAddress = feature.properties.full_address;

            this.addressTarget.value = fullAddress;
        }
    }
}

