import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["sidebarContainer", "icon", "link", "mainContent"];

    connect() {
        const isExpanded = localStorage.getItem("sidebarExpanded") === "true";
        if (isExpanded) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    toggle() {
        const expand = !this.isSidebarExpanded();
        if (expand) {
            this.expand();
        } else {
            this.collapse();
        }
        localStorage.setItem("sidebarExpanded", expand.toString());
    }

    expand() {
        this.sidebarContainerTarget.classList.add("w-52");
        this.mainContentTarget.classList.remove("lg:ml-14");
        this.mainContentTarget.classList.add("lg:ml-52");
        this.linkTargets.forEach(link => {
            link.classList.remove("sr-only");
        });
        this.updateIcon(true);
    }

    collapse() {

        this.sidebarContainerTarget.classList.remove("w-52");
        this.mainContentTarget.classList.remove("lg:ml-52");
        this.mainContentTarget.classList.add("lg:ml-14");
        this.linkTargets.forEach(link => {
            link.classList.add("sr-only");
        });
        this.updateIcon(false);
    }

    isSidebarExpanded() {
        return this.sidebarContainerTarget.classList.contains("w-52");
    }

    updateIcon(isExpanded) {
        const icon = this.iconTarget.querySelector("svg");
        if (isExpanded) {
            icon.innerHTML = `<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 3v14h1.5V3H3Zm8.03 9.97-2.22-2.22h8.69v-1.5H8.845l2.203-2.202-1.061-1.061-4.03 4.03L9.97 14.03l1.06-1.06Z"/>`;
        } else {
            icon.innerHTML = `<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M17 17V3h-1.5v14H17Zm-5.81-6.25H2.5v-1.5h8.69L8.97 7.03l1.06-1.06L14.06 10l-4.03 4.03-1.06-1.06 2.22-2.22Z"/>`;
        }
    }
}
